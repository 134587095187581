export const Colors = {
	PRIMARY: '#007CBA',

	BLACK: '#10161A',

	BLUE1: '#253E5D',
	BLUE2: 'rgba(0, 0, 0, 0.4)',
	BLUE5: '#48AFF0',

	COBALT1: '#1F4B99',
	COBALT2: '#2458B3',
	COBALT3: '#2965CC',
	COBALT4: '#4580E6',
	COBALT5: '#669EFF',

	DARK_GRAY1: 'rgba(0, 0, 0, 0.4)',
	DARK_GRAY2: '#2E2E2E',
	DARK_GRAY3: '#293742',
	DARK_GRAY4: '#30404D',
	DARK_GRAY5: '#394B59',

	GRAY1: '#5C7080',
	GRAY2: '#738694',
	GRAY3: '#8A9BA8',
	GRAY4: 'rgba(255, 255, 255, 0.7)',
	GRAY5: '#BFCCD6',

	GREEN3: '#0F9960',
	GREEN4: '#15B371',
	GREEN5: '#3DCC91',

	LIGHT_GRAY1: '#CED9E0',
	LIGHT_GRAY2: '#D8E1E8',
	LIGHT_GRAY3: '#E1E8ED',
	LIGHT_GRAY4: '#EBF1F5',
	LIGHT_GRAY5: '#F5F8FA',

	WHITE: '#FFFFFF',

	COLLECTED: '#253E5D',
	QUOTE_REQUESTED: '#2196F3',
	ORDERED: '#2196F3',
	IN_QA: '#2196F3',
	REJECTED: '#F40000',
	PROCESSING: '#FFC700',
	READY_FOR_QA: '#F45800',
	ACCEPTED: '#009217',
	COMPLETE: '#009217',
};
