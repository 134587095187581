/* eslint-disable prefer-const */
/* eslint-disable no-redeclare */
import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

import { LocationModal } from './Map';

const Background = styled.div`
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 1px;
	left: 130px;
`;

const ModalWrapper = styled.div`
	width: 1024px;
	height: 853px;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #fff;
	color: #000;
	display: grid;
	grid-template-columns: 1fr 1fr;
	position: relative;
	z-index: 10;
	border-radius: 5px;
	top: -10px;
`;

const Title = styled.div`
	padding-top: 25px;
	padding-left: 45px;
`;

const Subtitle = styled.div`
	position: absolute;
	padding-top: 200px;
	padding-left: 45px;
`;

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	line-height: 1.8;
	color: #141414;
	p {
		margin-bottom: 1rem;
	}
`;

export let optionModal = {
	showModal: true,
};

export const Modal = ({ showModal, setShowModal }) => {
	const modalRef = useRef();

	const animation = useSpring({
		config: {
			duration: 250,
		},
		opacity: showModal ? 1 : 0,
		transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
	});

	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModal(false);
		}
	};

	const closeModalProp = () => {
		setShowModal(false);
	};

	const keyPress = useCallback(
		(e) => {
			if (e.key === 'Escape' && showModal) {
				setShowModal(false);
				console.log('I pressed');
			}
		},
		[setShowModal, showModal],
	);

	useEffect(() => {
		document.addEventListener('keydown', keyPress);
		return () => document.removeEventListener('keydown', keyPress);
	}, [keyPress]);

	return (
		<>
			{showModal ? (
				<Background onClick={closeModal} ref={modalRef}>
					<animated.div style={animation}>
						<ModalWrapper showModal={showModal}>
							<Title>
								<h1>Add Location</h1>
							</Title>
							<Subtitle>
								<h2>Select Location</h2>
								<p>Click on the map below</p>
							</Subtitle>
							<ModalContent>
								<LocationModal closeModalProp={closeModalProp}></LocationModal>
							</ModalContent>
						</ModalWrapper>
					</animated.div>
				</Background>
			) : null}
		</>
	);
};
